<template>
	<div class="detail">
		<!-- <div class="album">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item, index) in detail.pics" :key="index">
					<el-image class="bannerPic" width="100%" :src="item" :preview-src-list="detail.pics"></el-image>
				</div>
			</div>
		</div> -->
		<swiper :options="swiperOption" ref="imgOverview">
			<swiper-slide v-for="(img, index) in detail.pics" :key="index">
				<div class="swiper-zoom-container">
				<img style="width:100%;" :src="img" alt="">
				</div>
			</swiper-slide>
		</swiper>
		<div class="main">
			<div class="goodsInfo">
				<div class="name">{{ detail.spuName }}</div>
				<div class="price">
					<span class="current">{{ detail.price }}€</span>
					<span class="origin">{{
            detail.originPrice == "0.00" ? "" : detail.originPrice + "€"
          }}</span>
				</div>
				<!-- <div class="title">颜色:</div>
				<div class="color">
					<div v-for="val in color.list" :class="`${val.id == color.current.id ? 'active' : ''} item`" :style="`background: ${val.color};`" @click="color.current = val" ></div>
				</div>
				<div class="line"></div> -->
				<div class="title">{{ $t("goodDetail.specification") }}:</div>
				<div class="sizes">
					<div v-for="(val, index) in detail.skus" :key="index"
						:class="`item ${active.skuId == val.skuId ? 'active' : ''}`" @click="active.skuId = val.skuId">
						{{ val.specInfo }}
					</div>
				</div>
				<!-- <div class="line"></div> -->
				<div class="buttons">
					<div class="btn deep" @click="addCart">{{ $t("goodDetail.addCart") }}</div>
					<div class="btn" @click="$bus.$emit('addLike', detail.spuId)">
						{{ $t("goodDetail.addCollect") }}
					</div>
				</div>
				<div class="title">{{ $t("tip.share") }}:</div>
				<div class="share">
					<img class="item" src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
					<img class="item" src="../../assets/icon/camera.png" @click="handleCamera()" />
					<!-- <img class="item" src="../../assets/icon/twitter.png" /> -->
				</div>
				<div class="title">{{ $t("goodDetail.detail") }}:</div>
				<div class="content"><img class="pdeatil" src="../../assets/icon/pdeatil.jpg" /></div>
				<div class="detail" v-html="detail.content"></div>
				<el-collapse>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/cloth.png" />
								<span>{{ $t("tip.washTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-if="instructions" v-html="instructions"></div>
						</div>
					</el-collapse-item>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/car.png" />
								<span>{{ $t("tip.postTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-if="distribution" v-html="distribution"></div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<div class="hot">
			<div class="title">{{ $t("tip.detailRecommend") }}</div>
			<div class="goods">
				<div class="item" v-for="val in recommand" :key="val.id">
					<GoodsItem :detail="val"></GoodsItem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	import Api from "../../api/request.js";
	import Swiper from "swiper";
	import { swiper, swiperSlide } from 'vue-awesome-swiper';
	import GoodsItem from "../../components/Goods/listItem.vue";
	export default {
		name: "GoodsDetail",
		components: {
			GoodsItem,
			swiper,
  			swiperSlide,
		},
		data() {
			return {
				color: {
					list: [{
							id: 1,
							color: "#000000"
						},
						{
							id: 2,
							color: "#E0E4E3"
						},
						{
							id: 3,
							color: "#7D5337"
						},
						{
							id: 4,
							color: "#BE2B35"
						},
					],
					current: {
						id: 1,
						color: "#000000"
					},
				},
				size: {
					list: [{
							id: 1,
							size: "XS"
						},
						{
							id: 2,
							size: "S"
						},
						{
							id: 3,
							size: "M"
						},
						{
							id: 4,
							size: "L"
						},
						{
							id: 5,
							size: "XL"
						},
					],
					current: {
						id: 1,
						color: "XS"
					},
				},
				detail: {},
				active: {
					skuId: "",
				},
				recommand: [],
				instructions: "", //洗衣指南
				distribution: "", // 配送
				swiperOption: {
					width: window.innerWidth,
					zoom : true,
					initialSlide: 0,
				},
			};
		},
		watch: {
    		'$route': function(to,from){
			    document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
			}
		},
		created() {
			this.getDetail();
		},
		computed: {
			...mapState(['cartTemp']),
		},
		methods: {
			...mapMutations(['setCartTemp']),
			getDetail() {
				this.$dialog.loading();
				Promise.all([
					new Promise((resolve, reject) => {
						Api.Goods.detail({
							spuId: this.$route.query.id
						}).then((res) => {
							this.detail = res.data.data;
							this.detail.pics.unshift(this.detail.cover);
							Api.Goods.recommand({
								spu_ids: res.data.data.recommendSpu
							}).then((res) => {
								this.recommand = res.data.data;
								resolve();
							});
						});
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.articleDetail({
							articleId: 111
						}).then(res => {
							this.instructions = res.data.data.content;
							console.log('洗衣指南------------------------------', res.data.data)
							resolve();
						})
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.articleDetail({
							articleId: 107
						}).then(res => {
							this.distribution = res.data.data.content;
							console.log('配送------------------------------', res.data.data)
							resolve();
						})
					}),
					// new Promise((resolve, reject) => {
					// 	Api.Index.recommand({
					// 		limit: 4,
					// 		label: 4
					// 	}).then((res) => {
					// 		if (res.data.data.list.length != 0) {
					// 			this.recommand = res.data.data.list;
					// 		}
					// 		resolve();
					// 	});
					// }),
				]).then((res) => {
					var mySwiper = new Swiper(".album", {
						loop: true,
						pagination: {
							el: ".swiper-pagination",
						},
					});
					this.$dialog.close();
				});
			},
			addCart() {
				if(this.$store.state.isLogin){
					if (this.active.skuId == "") {
						this.$bus.$emit("addCart", this.detail.skus[0].skuId);
					} else {
						this.$bus.$emit("addCart", this.active.skuId);
					}
				}else {
					let skuId = 0
					let tenparr = this.cartTemp
					console.log('this.active.skuId----------------', this.active.skuId)
					if (this.active.skuId == "") {
						skuId = this.detail.skus[0].skuId
					} else {
						skuId = this.active.skuId
					}
					let tempdetail = {
						skuId : skuId,
						spuName: this.detail.spuName,
						cover: this.detail.cover,
						price: this.detail.price,
						stock: this.detail.stock,
						specs: this.detail.specs,
						skus: this.detail.skus,
						num : 1
					}
				
					console.log('--详情handleAddCart-------------', tempdetail, this.detail)
					if(tenparr.length>0){
						let idx = tenparr.findIndex((item) => {
							return item.skuId == tempdetail.skuId; 
						});
						console.log('--详情idx-------------', idx)
						if( idx <= -1 ){
							tenparr.push(tempdetail)
						}else {
							tenparr[idx].num += tempdetail.num
						}
						
					}else{
						tenparr.push(tempdetail)
					}
					console.log('--结果-------------', tenparr)
					this.setCartTemp(tenparr);
					this.$bus.$emit("cartDialog");
				}
			},
			handleFaceBook() {
				window.location.href = "https://www.facebook.com/GIA-MIA-103239429025027";
			},
			handleCamera() {
				window.location.href = "https://www.instagram.com/giamiamarbella/";
			},
		},
	};
</script>

<style lang="less" scoped>
	.album {
		min-height: 100vw;
		background: #ccc;
	}

	.main {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		padding: 3vw;

		.goodsInfo {
			width: 100%;

			.name {
				font-size: 4vw;
				font-weight: bold;
			}

			.title {
				height: 10vw;
				font-size: 3.8vw;
				line-height: 10vw;
				font-weight: bold;
				margin-top: 6vw;
			}

			.line {
				height: 1px;
				background: #757575;
				margin-top: 3vw;
			}

			.price {
				.current {
					font-size: 3.5vw;
					font-weight: bold;
				}

				.origin {
					font-size: 3.2vw;
					font-weight: 400;
					margin-left: 18px;
					text-decoration: line-through;
				}
			}

			.color {
				display: flex;

				.item {
					width: 6vw;
					height: 6vw;
					border-radius: 50%;
					margin-right: 6vw;
					cursor: pointer;

					&.active {
						&::before {
							content: "";
							display: block;
							width: 4vw;
							height: 4vw;
							border: 0.5vw solid #fff;
							border-radius: 50%;
							margin: 0.5vw auto 0;
						}
					}
				}
			}

			.sizes {

				// display: flex;
				.item {
					font-size: 3vw;
					font-weight: bold;
					margin: 2vw 8vw 0 0;
					padding: 5px 10px;
					border-radius: 6px;
					border: 1px solid #fff;

					&.active {
						border-color: #000;
					}
				}
			}

			.buttons {
				display: flex;
				margin-top: 6vw;

				.btn {
					height: 10vw;
					padding: 0 2vw;
					background: #ffffff;
					border-radius: 6px;
					border: 1px solid #123178;
					text-align: center;
					line-height: 11vw;
					font-size: 12px;
					font-weight: 600;
					color: #123178;
					cursor: pointer;
					margin-right: 3vw;

					&.deep {
						background-color: #323232;
						border: 1px solid #323232;
						color: #fff;

						&:hover {
							background-color: #eecc77;
						}

					}

					&:hover {
						background-color: #021450;
						color: #fff;
					}
				}
			}

			.share {
				display: flex;

				.item {
					width: 24px;
					height: 24px;
					margin-right: 20px;
					cursor: pointer;
				}
			}

			.tip {
				display: flex;
				align-items: center;
				margin-top: 40px;
				cursor: pointer;

				&>img {
					width: 20px;
					height: 18px;
				}

				&>span {
					margin: 0 24px 0 16px;
					font-size: 14px;
					font-weight: 400;
				}

				&>i {
					font-size: 12px;
					font-weight: bolder;
				}
			}
		}
	}

	.hot {
		overflow: hidden;
		padding: 3vw;

		.title {
			height: 10vw;
			font-size: 3.8vw;
			line-height: 10vw;
			font-weight: bold;
			margin-top: 6vw;
		}

		.goods {
			display: flex;
			flex-wrap: wrap;

			.item {
				width: calc((100% - 6px) / 2);
				margin-right: 6px;
				margin-bottom: 15px;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	/deep/.el-collapse {
		border: none;

		.el-collapse-item {
			margin-top: 20px;

			.el-collapse-item__header {
				border-bottom: none;

				.el-collapse-item__arrow {
					display: none;
				}
			}
		}

		.el-collapse-item__wrap {
			border-bottom: none;
		}

		.el-collapse-item__content {
			padding-top: 8vw;
		}
	}
	.pdeatil{
		width: 100%;
	}
</style>
